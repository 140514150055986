import { extendTheme, baseTheme } from "@chakra-ui/react";

const theme = {
  fonts: {
    heading: "Source Serif Pro, serif",
    body: "Montserrat, sans-serif",
  },
  colors: {
    brand: {
      100: "#67eba5",
      200: "#4dd18b",
      300: "#33b771",
      400: "#1a9e58",
      500: "#00843e",
      600: "#006b25",
      700: "#00510b",
      800: "#003800",
      900: "#001e00",
    },
  },
};

export default extendTheme(theme);
export { baseTheme };
